import "./footer.css"

export const Footer = () => {
    return(
        <footer className="footer">
            <div className="container">
                <p>Feito com 💚 por Gabs!</p>
            </div>
        </footer>
    )
}